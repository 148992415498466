import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

//Images
import pic10 from '../../../../assets/images/project/Terravista1.JPG';
import pic11 from '../../../../assets/images/project/rio-lindo.JPG';
import pic13 from '../../../../assets/images/services/pic13.jpg';
import pic14 from '../../../../assets/images/project/ciudad_maya.JPG';
import pic15 from '../../../../assets/images/project/bulevarSuyapa.JPG';
import pic16 from '../../../../assets/images/project/mantenimientos.jpg';

// icons
import icon1blue from '../../../../assets/icons/edificio-blue.png';
import icon2blue from '../../../../assets/icons/street-blue.png';
import icon4blue from '../../../../assets/icons/presa-blue.png';
import icon5blue from '../../../../assets/icons/energia-blue.png';
import icon6blue from '../../../../assets/icons/casa-blue.png';
import icon7blue from '../../../../assets/icons/flyover-blue.png';
import icon8blue from '../../../../assets/icons/city-map-blue.png';
import icon9blue from '../../../../assets/icons/mantenimiento-blue.png';

// import Swiper core and required modules
import  { Autoplay, Parallax } from 'swiper/modules';
import SwiperCore from 'swiper'; 
SwiperCore.use([Parallax, Autoplay]);

//SwiperCore.use([EffectCoverflow,Pagination]);

const ServiceSliderData = [
	{
		image: pic10,
		title: 'Edificaciones Verticales',
		icon: icon1blue,
	},
	{ image: pic11, title: 'Infraestructura Vial', icon: icon2blue },
	{
		image: pic13,
		title: 'Obras Hidráulicas y de Protección',
		icon: icon4blue,
	},
	{
		image: pic14,
		title: 'Hidroeléctricas',
		icon: icon5blue,
	},
	{
		image: pic14,
		title: 'Complejos Residenciales',
		icon: icon6blue,
	},
	{
		image: pic10,
		title: 'Urbanización',
		icon: icon8blue,
	},
	{ image: pic15, title: 'Puentes y Pasos a Desnivel', icon: icon7blue },
	{
		image: pic16,
		title: 'Mantenimientos',
		icon: icon9blue,
	},
];

export const ServicesGrid = () => {
	return <>
		<div className='services-grid'>
			{
				ServiceSliderData.map((item, index) => {
					return (
						<div className='service-item' key={index}>
							<LazyLoadImage className='service-icon' src={item.icon} alt='Servicios William y Molina' />
							<h3>{item.title}</h3>
						</div>
					)
				})
			}
		</div>
	</>
}
