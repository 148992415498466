import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GlobalContext } from '../../../context/globalContext';
import logoWM from '../../../assets/images/logoWM.png';
import logoGP from '../../../assets/images/logo_grupo_platino.png';

export const socialMedia = [{
	name: 'Facebook',
	url: 'https://www.facebook.com/WMConstructoresHN/',
	icon: 'fab fa-facebook-f',
}, 
	{
		name: 'Instagram',
		url: 'https://www.instagram.com/wm_constructores/',
		icon: 'fab fa-instagram',
	}
];

export const Footer = () => {
	const { companyInfo } = React.useContext(GlobalContext);

	return (
		<>
			<footer style={{ padding: '2rem 0 0 0', display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }}>
  <div style={{
    display: 'flex',
    flexWrap: 'wrap',
    paddingInline: '2rem',
    gap: '1rem',
    justifyContent: 'space-between',
    maxWidth: '1200px',
    width: '100%'
  }}>
    <div style={{
	}}>
      <Link to={'./'}>
        <img
          style={{ height: '120px', marginTop: '-1.5rem', maxWidth: '100%', objectFit: 'contain' }}
          src={logoWM}
          alt='Logo William y Molina'
        />
      </Link>
    </div>

    {/* Company Info */}
    <p style={{ maxWidth: '400px' }} className='text-light'>
      Ser una empresa líder enfocada en brindar los mejores servicios de construcción a nuestros clientes, manteniendo nuestro enfoque fijo en los valores y principios de la empresa.
    </p>

    {/* Contact Info */}
    <ul style={{
      display: 'flex',
      width: '100%',
      maxWidth: '400px',
      alignItems: 'start',
      flexDirection: 'column',
      gap: '1rem'
    }}>
      <li>
        <i className='flaticon-placeholder'></i>
        <span style={{ marginLeft: '10px' }} className='text-light'>{companyInfo.address}</span>
      </li>
      <li>
        <i className='flaticon-call'></i>
        <span style={{ marginLeft: '10px' }}>
          <a className='text-light' href={`tel:+504 ${companyInfo.cellPhone}`}>+504 {companyInfo.cellPhone}</a>
        </span>
      </li>
    </ul>

    {/* Social Media Links */}
    <ul style={{
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'start',
      flexDirection: 'column',
      gap: '1rem'
    }}>
      {socialMedia.map((data, index) => (
        <li key={index}>
          <i className={data.icon}></i>
          <span>
            <a className='text-light' href={data.url} target='_blank' rel='noreferrer'>
              <span style={{ marginLeft: '10px' }}>{data.name}</span>
            </a>
          </span>
        </li>
      ))}
      <li>
        <i className='flaticon-chat-1'></i>
        <span style={{ marginLeft: '10px' }}>
          <a className='text-light' href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
        </span>
      </li>
    </ul>

    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: '2rem',
	  gap: '1rem',
    }}>
      <div style={{ maxWidth: '200px' }}>
        <LazyLoadImage effect='opacity' delayTime={300} src={logoGP} alt='Grupo Platino Logo' />
      </div>
      <div className='text-light'>
        Copyright © 2023 Platino Software
      </div>
      <div className='text-light'>
        Todos los derechos reservados.
      </div>
    </div>
  </div>

  {/* Responsive styles */}
  <style jsx>{`
    @media (max-width: 768px) {
      footer div {
        padding-inline: 1rem;
      }

      footer ul {
        flex-direction: column;
        max-width: 100%;
      }

      footer p {
        max-width: 100%;
        margin-top: 1rem;
      }

      footer div:last-child {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  `}</style>
</footer>

		</>
	);
}
