import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import { GlobalContext } from '../../../context/globalContext';
// import Swiper core and required modules
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

export const PolicySlider = () => {
	const navigationPrevRef = React.useRef(null);
	const navigationNextRef = React.useRef(null);
	const { companyInfo } = React.useContext(GlobalContext);
	let items = [
		{
			title: "Historia",
			description: companyInfo.history
		},
		{
			title: "Mision",
			description: companyInfo.mission
		},
		{
			title: "Vision",
			description: companyInfo.vision
		}
	]
	return (
		<>
			<Swiper
				className='swiper-container content-slider about-swiper slider-btn-1'
				speed={1500}
				parallax={true}
				slidesPerView={3}
				spaceBetween={0}
				loop={true}
				pagination={{
					type: 'fraction',
				}}
				autoplay={{
					delay: 3100,
				}}
				navigation={{
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}
				onSwiper={(swiper) => {
					// Delay execution for the refs to be defined
					setTimeout(() => {
						// Override prevEl & nextEl now that refs are defined
						swiper.params.navigation.prevEl = navigationPrevRef.current;
						swiper.params.navigation.nextEl = navigationNextRef.current;

						// Re-init navigation
						swiper.navigation.destroy();
						swiper.navigation.init();
						swiper.navigation.update();
					});
				}}
				modules={[Navigation, Autoplay, Pagination]}
				breakpoints={{
					1200: {
						slidesPerView: 3,
					},
					992: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 2,
					},
					320: {
						slidesPerView: 1,
					},
				}}
			>
				{items.map((data, i) => (
					<SwiperSlide key={i}>
						<div className='content-box2 overlay-shine d-flex flex-column justify-content-between'>
							<div className='dz-info'>
								<h3 className='title'>{data.title}</h3>
								<p>
									{data.description}{' '}
								</p>
							</div>
						</div>
					</SwiperSlide>
				))}
				<div className='swiper-button'>
					<div
						className='button-prev swiper-button-prev4'
						ref={navigationPrevRef}
					>
						<i className='las la-arrow-left'></i>
					</div>
					<div
						className='button-next swiper-button-next4'
						ref={navigationNextRef}
					>
						<i className='las la-arrow-right'></i>
					</div>
				</div>
			</Swiper>
		</>
	);
}
