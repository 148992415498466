import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/thumbs';

//Images
import logo1 from './../../assets/images/logos/cnbs_logo.jpeg';
import logo2 from './../../assets/images/logos//greenValley_logo.jpeg';
import logo3 from './../../assets/images/logos/invalle_logo.jpeg';
import logo4 from './../../assets/images/logos/gob_hnd_logo.jpeg';
import logo5 from './../../assets/images/logos/siglo21_logo.png';
import logo6 from './../../assets/images/logos/dc_logo.png';
import logo7 from './../../assets/images/logos/ultracem_logo.png';
import logo8 from './../../assets/images/logos/logo__Altia.svg';
import logo9 from './../../assets/images/logos/muni_sps_logo.jpeg';
import logo10 from './../../assets/images/logos/sc-sv.png';
import { Autoplay, Navigation, Thumbs } from 'swiper/modules';


//SwiperCore.use([EffectCoverflow,Pagination]);

const clientData = [
	{ image: logo1 },
	{ image: logo10 },
	{ image: logo5 },
	{ image: logo4 },
	{ image: logo9 },
	{ image: logo3 },
	{ image: logo6 },
	{ image: logo2 },
	{ image: logo7 },
	{ image: logo8 },
];

export const CustomersSlider = () => {
	return (
		<section className='content-inner-6'>
			<div className='mx-auto px-4'>
				<div className='section-head style-1 text-center'>
					<h5 className='text-primary sub-title'>Nuestros Clientes</h5>
				</div>
				<Swiper
					autoplay={
						{
							pauseOnMouseEnter: true,
							delay: 2500,
						}
					}
					navigation
					modules={[Navigation, Thumbs, Autoplay]}
					className='swiper-container mx-md-5'
					parallax={true}
					slidesPerView={4}
					spaceBetween={30}
					loop={true}
					breakpoints={{
						1191: {
							slidesPerView: 6,
						},
						991: {
							slidesPerView: 5,
						},
						691: {
							slidesPerView: 4,
						},
						591: {
							slidesPerView: 3,
						},
						320: {
							slidesPerView: 2,
						},
					}}
				>
					{clientData.map((d, i) => (
						<SwiperSlide key={i + d.image}>
							<div className='clients-logo'>
								<LazyLoadImage effect='opacity' className='logo-main' src={d.image} alt='Clientes de William y Molina' />
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
};
