import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// context
import { GlobalContext } from '../../../context/globalContext';
import image from '../../../assets/images/image-none.svg';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

export const ProjectsSlider = () => {
	const { projects } = useContext(GlobalContext);
	const navigationPrevRef = React.useRef(null);
	const navigationNextRef = React.useRef(null);
	return (
		<section className='px-md-5'>
			<div className='container-fluid'>
				<div className='section-head text-center style-1'>
					<h5 className='sub-title text-primary'>Nuestros proyectos</h5>
					<p>
						WM Constructores trabaja bajo las normas ISO 9001:2015 y está calificada para realizar trabajos de construcciónn
						para los principales inversionistas en este rubro, tanto de carácter público como privado en Honduras
					</p>
				</div>
				<div
					className='container-fluid p-0 aos-item'
					data-aos='fade-up'
					data-aos-easing='ease-in-back'
					data-aos-delay='200'
				>
					<Swiper
						className='swiper-container image-slider-wrapper px-md-5'
						slidesPerView={5}
						spaceBetween={0}
						loop={false}
						onSwiper={(swiper) => {
							// Delay execution for the refs to be defined
							setTimeout(() => {
								// Override prevEl & nextEl now that refs are defined
								if (swiper.params?.navigation) {
									swiper.params.navigation.prevEl = navigationPrevRef.current;
									swiper.params.navigation.nextEl = navigationNextRef.current;

									// Re-init navigation
									swiper.navigation.destroy();
									swiper.navigation.init();
									swiper.navigation.update();
								}
							});
						}}
						modules={[Navigation]}
						breakpoints={{
							1400: {
								slidesPerView: 5,
							},
							991: {
								slidesPerView: 3,
							},
							600: {
								slidesPerView: 2,
							},
							320: {
								slidesPerView: 1,
							},
						}}
					>
						{projects.map((item, ind) => (
							<SwiperSlide key={ind}>
								<div className='image-box'>
									<div className='dz-media'>
										<LazyLoadImage effect='opacity' width={'100%'} height={'100%'} src={item.images.length !== 0 ? item.images[0].url : image} alt='Proyectos William y Molina' />
									</div>
									<div className='info'>
										<h3>
											<Link
												to={`./project-details/${item.projectId}`}
												className='text-white btn-link'
											>
												{item.name} <i className='fas fa-arrow-right'></i>
											</Link>
										</h3>
									</div>
								</div>
							</SwiperSlide>
						))}
						<div className='swiper-button'>
							<div
								className='button-prev swiper-button-prev-service'
								ref={navigationPrevRef}
							>
								<i className='las la-angle-left'></i>
							</div>
							<div
								className='button-next swiper-button-next-service'
								ref={navigationNextRef}
							>
								<i className='las la-angle-right'></i>
							</div>
						</div>
					</Swiper>
				</div>
			</div>
		</section>
	);
};
